.lading-page {
  padding: 40px 0;
  width: 100%;
  height: auto;
  min-height: 730px;
  .wrap {
    .hero-section {
      gap: 20px;
      margin-top: 40px;
      height: auto;
      .hero-left {
        flex: 1;
        height: auto;
        .sec-title {
          font-family: "Luckiest Guy";
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 64px;
          text-align: center;
          letter-spacing: 0.1em;
          color: #f7f7f7;
        }
        .sec-desc {
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 28px;
          letter-spacing: 0.05em;
          color: #f7f7f7;
          margin: 16px 0;
        }
        .nfts-grid {
          grid-gap: 12px;
          // display: grid;
          width: 100%;
          margin-top: 30px;
          grid-template-columns: repeat(3, 1fr);
          .grid-item {
            width: 100%;
            height: auto;
            .img {
              height: 70px;
              width: 70px;
            }
            .numb {
              margin-top: 12px;
              font-weight: 400;
              font-size: 22px;
              letter-spacing: 0.05em;
              color: #f7f7f7;
            }
            .name {
              font-weight: 400;
              font-size: 16px;
              letter-spacing: 0.05em;
              color: #f7f7f7;
              line-height: 10px;
            }
          }
        }
        @media screen and (min-width: 1200px) {
          .sec-title {
            font-size: 60px;
            line-height: 80px;
          }

          .sec-desc {
            font-size: 40px;
            line-height: 36px;
          }
        }

        @media screen and (max-width: 1199px) and (min-width: 768px) {
          .sec-title {
            font-size: 52px;
            line-height: 72px;
          }

          .sec-desc {
            font-size: 36px;
            line-height: 32px;
          }
        }

        @media screen and (max-width: 767px) {
          .sec-title {
            font-size: 44px;
            line-height: 64px;
          }

          .sec-desc {
            font-size: 28px;
            line-height: 24px;
          }
        }
      }
      .hero-right {
        flex: 0.9;
        height: auto;
        .bg {
          height: 430px;
          width: 100%;
          background-image: url("../../../public/images/bg.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        }
      }
    }
    .staking-grid {
      grid-gap: 12px;
      // display: grid;
      width: 100%;
      margin-top: 30px;
      grid-template-columns: repeat(3, 1fr);
      .stake-item {
        width: 100%;
        height: auto;
        padding: 30px 20px;
        border-radius: 10px;
        border: 1px solid rgba(247, 247, 247, 0.5);
        .desc {
          p {
            font-family: "VT323";
            font-style: normal;
            font-weight: 300;
            font-size: 38px;
            line-height: 34px;
            letter-spacing: 0.05em;
            color: #f7f7f7;
          }
        }
        .s-img {
          height: 130px;
          width: 130px;
          object-fit: contain;
          margin: 20px 0;
        }
        .btn-stack {
          font-size: 24px;
          font-weight: 500;
          line-height: 32px;
          color: #151515;
          width: 100%;
          padding: 8px 12px;
        }
      }
    }
    .ready-stake-section {
      margin: 40px 0 0 0;
      padding: 0;
      width: 100%;
      .stack-sec-tabs {
        gap: 12px;
        .tab {
          padding: 12px;
          border: 1px solid rgba(247, 247, 247, 0.5);
          border-radius: 10px 10px 0px 0px;
          cursor: pointer;
          .icon {
            height: 38px;
            width: 38px;
          }
          .tab-tag {
            font-family: "VT323";
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 36px;
            letter-spacing: 0.05em;
            color: rgba(247, 247, 247, 0.5);
            margin: 0 18px;
          }
          .numb {
            width: 50px;
            font-size: 18px;
            padding: 2px;
            color: #151515;
            background: #fdff93;
            border-radius: 40px;
          }
          &.active {
            .tab-tag {
              color: #00bd8a;
            }
          }
        }
      }
      .ready-stake-grid {
        grid-gap: 20px;
        display: grid;
        width: 100%;
        padding: 60px 30px;
        grid-template-columns: repeat(4, 1fr);
        border: 1px solid rgba(247, 247, 247, 0.5);
        border-radius: 0px 10px 10px 10px;
        .ready-stake-item {
          width: 100%;
          height: auto;
          background: #a6abbd;
          border: 1px solid rgba(13, 13, 13, 0.5);
          border-radius: 10px;
          padding: 30px 20px;

          .id-sec {
            padding: 12px 18px;
            justify-content: space-between;
            .id {
              font-family: "VT323";
              font-style: normal;
              font-weight: 400;
              font-size: 28px;
              line-height: 31px;
              letter-spacing: 0.05em;
              color: #151515;
            }
            .status {
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              color: #151515;
              background: #33a0ff;
              border-radius: 40px;
              padding: 4px 20px;
            }
          }
          .img {
            margin: 20px 0;
            width: 100%;
            height: 168px;
            object-fit: contain;
          }
          .card-info {
            align-items: center;
            margin-bottom: 20px;
            .name {
              font-style: normal;
              font-weight: 400;
              font-size: 25px;
              line-height: 30px;
              letter-spacing: 0.05em;
              color: #151515;
            }
            .time {
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: 0.05em;
              color: #151515;
            }
          }
          .btn {
            color: #151515;
          }
        }
        .ready-staked-item {
          width: 100%;
          height: auto;
          background: #a6abbd;
          border: 1px solid rgba(13, 13, 13, 0.5);
          border-radius: 10px;
          padding: 30px 20px;

          .id-sec {
            padding: 12px 18px;
            justify-content: space-between;
            .id {
              font-family: "VT323";
              font-style: normal;
              font-weight: 400;
              font-size: 28px;
              line-height: 31px;
              letter-spacing: 0.05em;
              color: #151515;
            }
            .status {
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              color: #151515;
              background: #fdff93;
              border-radius: 40px;
              padding: 4px 20px;
            }
          }
          .img {
            margin: 20px 0;
            width: 100%;
            height: 168px;
            object-fit: contain;
          }
          .card-info {
            align-items: start;
            margin-bottom: 20px;
            .name {
              font-style: normal;
              font-weight: 400;
              font-size: 25px;
              line-height: 30px;
              letter-spacing: 0.05em;
              color: #151515;
            }
            .time {
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              letter-spacing: 0.05em;
              color: #151515;
            }
          }
          .btn {
            color: #151515;
          }
          .btn-claim {
            margin-top: 15px;
            color: #151515;
            border-color: #f7f7f7;
            background: #f7f7f7;
          }
        }
      }
      .wallet-connection-sec {
        width: 100%;
        min-height: 440px;
        padding: 30px;
        border: 1px solid rgba(247, 247, 247, 0.5);
        border-radius: 0px 10px 10px 10px;
        .image {
          height: 150px;
          width: 190px;
        }
        .c-desc {
          font-family: "VT323";
          font-style: normal;
          font-weight: 400;
          font-size: 26px;
          line-height: 30px;
          letter-spacing: 0.05em;
          color: #f7f7f7;
          margin: 30px 0;
        }
        .actions {
          gap: 30px;
          ._btn {
            color: #151515;
            font-family: "VT323";
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 24px;
            &:last-child {
              border-color: #f7f7f7;
              background: #f7f7f7;
            }
          }
          @media screen and (min-width: 1200px) {
            ._btn {
              font-weight: 400;
              font-size: 28px;
              line-height: 24px;
            }
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            ._btn {
              font-weight: 300;
              font-size: 20px;
              line-height: 22px;
            }
          }
          @media screen and (max-width: 767px) {
            ._btn {
              font-weight: 200;
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
      @media screen and (min-width: 1200px) {
        .ready-stake-grid {
          grid-template-columns: repeat(4, 1fr);
        }
      }
      @media screen and (max-width: 1199px) and (min-width: 768px) {
        .ready-stake-grid {
          grid-template-columns: repeat(2, 1fr);
        }

        .sec-desc {
          font-size: 36px;
          line-height: 32px;
        }
      }
      @media screen and (max-width: 767px) {
        .ready-stake-grid {
          grid-template-columns: repeat(1, 1fr);
        }

        .sec-desc {
          font-size: 28px;
          line-height: 24px;
        }
      }
    }
  }
}
