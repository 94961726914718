$pageWidth: 210px;
$pageWidth65: 50px;

.page-width {
  width: $pageWidth;
}

.page-width65 {
  width: $pageWidth65;
}
