// Footer
.footer-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 360px;
  padding: 30px;
  background: #a6abbd;
  .wrap {
    gap: 30px;
    .top-part {
      width: 100%;
      gap: 20px;
      font-family: "Poppins", sans-serif;
      .top-left {
        flex: 1;
        .logo-img {
          height: 200px;
          width: 200px;
        }
        .about-proj {
          padding: 14px 30px;
          .proj-name {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 36px;
            color: #151515;
          }
          .proj-desc {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            color: #151515;
          }
          @media screen and (max-width: 1199px) and (min-width: 768px) {
            .proj-name {
              font-weight: 600;
              font-size: 18px;
              line-height: 33px;
            }
          }
          @media screen and (max-width: 767px) {
            .proj-name {
              font-weight: 600;
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
      .top-center {
        flex: 1;
        .menu-list {
          .menu-item {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 26px;
            color: #151515;
            text-align: center;
            margin-bottom: 18px;
            cursor: pointer;
            @include anim;
            &:hover {
              color: $themeColor;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        @media screen and (max-width: 1199px) and (min-width: 768px) {
          .manu-list {
            margin-right: 100px;
          }
        }
      }
      .top-right {
        flex: 1;
        .social {
          gap: 30px;
          .ico {
            height: 28px;
            width: 28px;
          }
        }
      }
    }
    .btm-part {
      .copy-right {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #151515;
      }
      .developed-by {
        margin-top: 8px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #151515;
        .c-logo {
          height: 18px;
          width: 18px;
        }
        .c-name {
          text-decoration: underline;
        }
      }
    }
  }
}
